import { MemoizedItemPanel } from '../ItemPanel';
import BreadcrumbsWithMenu from '../BreadcrumvsWithMenuProps';
import { CloseButton } from '../button';
import {
  ItemLevel,
  setCurrentItem,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box } from '../Box';
import { palette } from '../../themes';

export const ItemPanelPage = ({ isIMC = true }: { isIMC?: boolean }) => {
  const levels = useAppSelector((state) => state.itemModal.navigation.levels);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [queryParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState('summary');

  useEffect(() => {
    const _selectedTab = queryParams.get('tab');

    if (_selectedTab !== selectedTab) setSelectedTab(_selectedTab);
  }, [queryParams]);

  const [itemId, setItemId] = useState(id);

  const handleAction = (crumb?: ItemLevel) => {
    const newCrumb: ItemLevel = {
      ...crumb,
      id: crumb?.id ?? '',
      itemId: crumb?.itemId ?? '',
      name: crumb?.name ?? '',
    };
    setItemId(crumb?.itemId);
    if (newCrumb) dispatch(setCurrentItem(newCrumb));
  };

  const handleClose = () => {
    window.parent.postMessage({ type: 'closeDetailsPage' }, '*');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box
        background='light'
        style={{
          flexShrink: 0,
          flexGrow: 0,
          padding: '0.25rem 1.25rem 0.25rem 1.5rem',
          borderBottom: '0.063rem',
          borderColor: palette.bley[400],
        }}
        padding='small'
        direction='row'
        alignItems='center'
      >
        <BreadcrumbsWithMenu
          crumbs={levels}
          color='#0A0B33CC'
          onClick={handleAction}
        />
        <Box
          direction='row'
          background='none'
          alignItems='center'
          justifyContent='end'
          height='3rem'
          width='100%'
          style={{ flex: '1 1 auto' }}
        >
          {isIMC && (
            <CloseButton sx={{ color: '#0A0B33CC' }} onClick={handleClose} />
          )}
        </Box>
      </Box>
      <MemoizedItemPanel
        initialId={id}
        id={itemId}
        initialTab={selectedTab}
        clearLevels={true}
        setId={setItemId}
      />
    </div>
  );
};
