import {
  Box,
  Button as MuiButton,
  Typography as MuiTypography,
  Typography,
} from "@mui/material";
import { Button } from "./button";
import * as MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ButtonProps } from "@mui/material/Button";
import * as React from "react";
import { palette, sizes } from "../themes";
import { Tooltip } from "./Tooltip";
import { Camera20Regular, MoreHorizontal20Filled } from "@fluentui/react-icons";
import { ItemLevel } from "../store";

type MuiButtonProps = React.ComponentProps<typeof MuiButton>;
type MuiTypographyProps = React.ComponentProps<typeof MuiTypography>;
export type Crumb = {
  id: string;
  name: string;
  icon?: React.ReactNode;
};

export type BreadcrumbsWithMenuProps = {
  text?: string;
  textVariant?: MuiTypographyProps["variant"];
  textFontSize?: MuiTypographyProps["fontSize"];
  color?: string;
  hoverColor?: MuiButtonProps["color"] | "white";
  crumbs: ItemLevel[];
  onClick?: (crumb: ItemLevel) => void;
  seperator?: React.ReactNode | "string";
  hideCurrentCrumb?: boolean;
  lastCrumbColor?: string;
} & Omit<ButtonProps, "color" | "variant" | "children" | "onClick">;

export default function BreadcrumbsWithMenu({
  startIcon,
  crumbs = [],
  onClick,
  color,
  seperator = "/",
  lastCrumbColor = "#000",
  hideCurrentCrumb = true,
}: BreadcrumbsWithMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Spacer = () => (
    <Button
      size="small"
      disableRipple={true}
      sx={{
        background: "none",
        cursor: "default",
        padding: "0 !important",
        display: "inline-flex",
        minWidth: "unset",
        "& p": {
          width: "fit-content",
        },
        color: palette.purewhite,
        "&:hover": {
          background: "none",
        },
      }}
    >
      <Typography variant="body2" color={color}>
        {seperator}
      </Typography>
    </Button>
  );

  const conditionallyRenderSpacer = crumbs[1] != undefined && <Spacer />;

  const Item = ({
    item,
    maxWidth,
    isFirst,
    color = palette.navy[900],
  }: {
    item: ItemLevel;
    maxWidth: string;
    isFirst?: boolean;
    color?: string;
  }) => (
    <Button
      size="small"
      startIcon={startIcon && isFirst && startIcon}
      onClick={() => onClick(item)}
      disableRipple
      sx={{
        color: palette.purewhite,
        background: "none",
        padding: sizes.padding.small,
        minWidth: "0",
        maxWidth: `calc(${maxWidth})`,
        "&:hover": {
          boxShadow: "none",
          background: "none",
          textDecoration: "underline",
        },
      }}
    >
      <Typography
        sx={{
          whiteSpace: "nowrap",
          width: "100%",
          lineHeight: "26px",
          display: "flex"
        }}
        variant="body1"
        color={color}
      >
        <Tooltip placement="top" title={item?.name}>
          <Box
            sx={{
              "&:hover": {
                textDecoration: "underline",
              },
              "& svg": {
                color: color,
              },
            }}
            className="encompaas-breadcrumb"
            style={{
              display: "inline-flex",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item?.icon && <div
              style={{
                float: "left",
                paddingRight: "0.25rem",
                display: "flex"
              }}
            >
              {item?.icon}
            </div>}
            <div
              style={{
                width: "100%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
              }}
            >
              {item?.name}
            </div>
          </Box>
        </Tooltip>
      </Typography>
    </Button>
  );

  const renderCrumbs = () => {
    switch (true) {
      //hide current crumb with a list of 5 or less crumbs
      case hideCurrentCrumb && crumbs.length > 1 && crumbs.length < 6:
        return (
          <>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              aria-labelledby="breadcrumbs"
              slotProps={{
                paper: {
                  style: {
                    height: "12rem",
                  },
                  sx: {
                    "& ul": {
                      paddingTop: "0.5rem",
                      paddingBottom: "0.5rem",
                    },
                  },
                },
              }}
              sx={{}}
              data-testid="encompaas-breadcrumbs-list"
            >
              {crumbs.slice(1, -4).map((x) => (
                <MenuItem
                  key={x.id}
                  onClick={() =>
                    onClick({
                      id: x.id,
                      itemId: x.id,
                      name: x.name,
                    })
                  }
                  sx={{
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                    flexDirection: "row",
                    "& svg": {
                      transform: "scale(1.3)",
                    },
                    "&:hover": {
                      color: `${palette.navy[900]} !important`,
                      fill: `${palette.navy[900]}`,
                      "& svg": {
                        color: `${palette.navy[900]}`,
                      },
                      borderRadius: "0 !important",
                    },
                  }}
                >
                  <div style={{ transform: `translateX(2rem)` }}>{x.icon}</div>
                  {x.name}
                </MenuItem>
              ))}
            </Menu>
            <MUIBreadcrumbs.default
              sx={{
                width: "100%",
                "& li": {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                },
              }}
              aria-label="breadcrumbs"
              data-testid="encompaas-breadcrumbs"
            >
              <>
                {crumbs[1] != undefined && (
                  <Item
                    item={crumbs.at(0)}
                    maxWidth="20%"
                    isFirst
                    color={color}
                  />
                )}
                {conditionallyRenderSpacer}

                {crumbs.slice(2, 5).map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      {x != undefined && (
                        <Item
                          item={crumbs[i + 1]}
                          maxWidth={`${17.5 - 2.5 * i}%`}
                          color={color}
                        />
                      )}
                      {x != undefined && <Spacer />}
                    </React.Fragment>
                  );
                })}
              </>
            </MUIBreadcrumbs.default>
          </>
        );
      case !hideCurrentCrumb && crumbs.length > 1 && crumbs.length < 6:
        return (
          <>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              aria-labelledby="breadcrumbs"
              slotProps={{
                paper: {
                  style: {
                    height: "12rem",
                  },
                  sx: {
                    "& ul": {
                      paddingTop: "0.5rem",
                      paddingBottom: "0.5rem",
                    },
                  },
                },
              }}
              sx={{}}
              data-testid="encompaas-breadcrumbs-list"
            >
              {crumbs.slice(1, -4).map((x) => (
                <MenuItem
                  key={x.id}
                  onClick={() =>
                    onClick({
                      id: x.id,
                      itemId: x.id,
                      name: x.name,
                    })
                  }
                  sx={{
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                    flexDirection: "row",
                    "& svg": {
                      transform: "scale(1.3)",
                    },
                    "&:hover": {
                      color: `${palette.navy[900]} !important`,
                      fill: `${palette.navy[900]}`,
                      "& svg": {
                        color: `${palette.navy[900]}`,
                      },
                      borderRadius: "0 !important",
                    },
                  }}
                >
                  <div style={{ transform: `translateX(2rem)` }}>{x.icon}</div>
                  {x.name}
                </MenuItem>
              ))}
            </Menu>
            <MUIBreadcrumbs.default
              sx={{
                width: "100%",
                "& li": {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                },
              }}
              aria-label="breadcrumbs"
              data-testid="encompaas-breadcrumbs"
            >
              <>
                {crumbs[1] != undefined && (
                  <Item
                    item={crumbs.at(0)}
                    maxWidth="20%"
                    isFirst
                    color={color}
                  />
                )}
                {conditionallyRenderSpacer}

                {crumbs.slice(1, 5).map((x, i, row) => {
                  return (
                    <React.Fragment key={i}>
                      {x != undefined && (
                        <Item
                          item={crumbs[i + 1]}
                          maxWidth={`${17.5 - 2.5 * i}%`}
                          color={color}
                        />
                      )}
                      {x != undefined &&
                      !hideCurrentCrumb &&
                      i + 1 === row.length ? (
                        <></>
                      ) : (
                        <Spacer />
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            </MUIBreadcrumbs.default>
          </>
        );
      //hide current crumb with a list of 6 or more crumbs
      case hideCurrentCrumb && crumbs.length > 1 && crumbs.length >= 6:
        return (
          <>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              aria-labelledby="breadcrumbs"
              slotProps={{
                paper: {
                  style: {
                    height: "12rem",
                  },
                  sx: {
                    "& ul": {
                      paddingTop: "0.5rem",
                      paddingBottom: "0.5rem",
                    },
                  },
                },
              }}
              data-testid="encompaas-breadcrumbs-list"
            >
              {crumbs.slice(1, -4).map((x) => (
                <MenuItem
                  key={x.id}
                  onClick={() =>
                    onClick({
                      id: x.id,
                      itemId: x.id,
                      name: x.name,
                    })
                  }
                  sx={{
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                    flexDirection: "row",
                    "& svg": {
                      transform: "scale(1.3)",
                    },
                    "&:hover": {
                      color: `${palette.navy[900]} !important`,
                      fill: `${palette.navy[900]}`,
                      "& svg": {
                        color: `${palette.navy[900]}`,
                      },
                      borderRadius: "0 !important",
                    },
                  }}
                >
                  {x.icon}
                  {x.name}
                </MenuItem>
              ))}
            </Menu>
            <MUIBreadcrumbs.default
              sx={{
                width: "100%",
                "& li": {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                },
              }}
              aria-label="breadcrumbs"
              data-testid="encompaas-breadcrumbs"
            >
              <>
                <Item
                  item={crumbs.at(0)}
                  maxWidth="20%"
                  isFirst
                  color={color}
                />
                {conditionallyRenderSpacer}
                <Button
                  size="small"
                  disableRipple={true}
                  onClick={handleClick}
                  sx={{
                    background: "none",
                    textOverflow: "ellipsis",
                    padding: sizes.padding.small,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "calc(17.5%)",
                    cursor: "default",
                    color: palette.aqua[800],
                    paddingX: "0.25rem",
                    height: "1.625rem",
                    marginY: "auto",
                  }}
                  data-testid="encompaas-breadcrumbs-expand"
                >
                  <MoreHorizontal20Filled />
                </Button>
                {conditionallyRenderSpacer}
                <Item
                  item={crumbs.at(-4)}
                  maxWidth="15%"
                  color={color}
                />
                {conditionallyRenderSpacer}
                <Item
                  item={crumbs.at(-3)}
                  maxWidth="12.5%"
                  color={color}
                />
                {conditionallyRenderSpacer}
                <Item
                  item={crumbs.at(-2)}
                  maxWidth="10%"
                  color={color}
                />
                {conditionallyRenderSpacer}
              </>
            </MUIBreadcrumbs.default>
          </>
        );
      case !hideCurrentCrumb && crumbs.length > 1 && crumbs.length >= 6:
        return (
          <>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              aria-labelledby="breadcrumbs"
              slotProps={{
                paper: {
                  style: {
                    height: "12rem",
                  },
                  sx: {
                    "& ul": {
                      paddingTop: "0.5rem",
                      paddingBottom: "0.5rem",
                    },
                  },
                },
              }}
              data-testid="encompaas-breadcrumbs-list"
            >
              {crumbs.slice(1, -3).map((x) => (
                <MenuItem
                  key={x.id}
                  onClick={() =>
                    onClick({
                      id: x.id,
                      itemId: x.id,
                      name: x.name,
                    })
                  }
                  sx={{
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                    flexDirection: "row",
                    "& svg": {
                      transform: "scale(1.3)",
                    },
                    "&:hover": {
                      color: `${palette.navy[900]} !important`,
                      fill: `${palette.navy[900]}`,
                      "& svg": {
                        color: `${palette.navy[900]}`,
                      },
                      borderRadius: "0 !important",
                    },
                  }}
                >
                  {x.icon}
                  {x.name}
                </MenuItem>
              ))}
            </Menu>
            <MUIBreadcrumbs.default
              sx={{
                width: "100%",
                "& li": {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                },
              }}
              aria-label="breadcrumbs"
              data-testid="encompaas-breadcrumbs"
            >
              <>
                <Item
                  item={crumbs.at(0)}
                  maxWidth="20%"
                  isFirst
                  color={color}
                />
                {conditionallyRenderSpacer}
                <Button
                  size="small"
                  disableRipple={true}
                  onClick={handleClick}
                  sx={{
                    background: "none",
                    textOverflow: "ellipsis",
                    padding: sizes.padding.small,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "calc(17.5%)",
                    cursor: "default",
                    color: palette.aqua[800],
                    paddingX: "0.25rem",
                    height: "1.625rem",
                    marginY: "auto",
                  }}
                  data-testid="encompaas-breadcrumbs-expand"
                >
                  <MoreHorizontal20Filled />
                </Button>
                {conditionallyRenderSpacer}
                <Item
                  item={crumbs.at(-3)}
                  maxWidth="15%"
                  color={color}
                />
                {conditionallyRenderSpacer}
                <Item
                  item={crumbs.at(-2)}
                  maxWidth="12.5%"
                  color={color}
                />
                {conditionallyRenderSpacer}
                <Item
                  item={crumbs.at(-1)}
                  maxWidth="10%"
                  color={lastCrumbColor}
                />
              </>
            </MUIBreadcrumbs.default>
          </>
        );
      default:
        return conditionallyRenderSpacer;
    }
  };

  return renderCrumbs();
}
